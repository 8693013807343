/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Rating,
  TableHead,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { useGetFeedbackQuery } from "../../services/shipmentTrack";

const FeedbackReview = () => {
  const navigate = useNavigate();
  const { data, isError, isSuccess } = useGetFeedbackQuery({ query: "react" });
  const [feedbackData, setFeedbackData] = useState<any>([]);
  useEffect(() => {
    if (isSuccess) {
      setFeedbackData(data?.data);
      console.log("data?.data :", data?.data?.data);
    }
  }, [isSuccess, data]);
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
  const open3 = Boolean(anchorEl3);
  const handleClick3 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  return (
    <>
      <div className="cards table_card">
        <div className="cards_header">
          <div className="left_s">
            <h2>
              {/* Store  */}
              Feedback and Review
            </h2>
            <p>{feedbackData?.count} feedbacks</p>
          </div>
          {/* <div className="right_s">
            <p>
              <span>Month</span>
              <span>Week</span>
              <span>Today</span>
            </p>
            <Button className="btn btn_highlight">
              <img src="/static/images/upload_icon.svg" alt="Icon" /> Export
            </Button>
          </div> */}
        </div>
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Traveller</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Feedback</TableCell>
                <TableCell>Rating</TableCell>
                {/* Uncomment if you want status and actions columns */}
                {/* <TableCell><b>Status</b></TableCell>
    <TableCell><b>Actions</b></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {feedbackData?.data?.slice(0, 3)?.map((data: any) => (
                <TableRow key={data?.id || Math.random()}>
                  <TableCell>
                    <div className="user_block">
                      <figure
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/profile-detail/${data?.traveller?._id}`)
                        }
                      >
                        <img
                          src={
                            data?.traveller?.personVerification?.profileImage
                              ? data?.traveller?.personVerification
                                  ?.profileImage
                              : "/static/images/user-placeholder.jpg"
                          }
                          alt=""
                        />
                      </figure>
                      <p>
                        <b>
                          {data?.traveller?.firstName
                            ? `${data?.traveller?.firstName} ${data?.traveller?.lastName}`
                            : "-"}
                        </b>
                      </p>
                    </div>
                  </TableCell>
                  <TableCell>
                    {data?.traveller?.phone
                      ? `${data?.traveller?.countryCode} ${data?.traveller?.phone}`
                      : "-"}
                  </TableCell>
                  <TableCell>
                    {data?.traveller?.email ? data?.traveller?.email : "-"}
                  </TableCell>
                  <TableCell className="max_c">
                    <p>
                      {data?.feedback?.[0]?.feedback
                        ? data?.feedback?.[0]?.feedback
                        : "No feedback given"}
                    </p>
                  </TableCell>
                  <TableCell>
                    <div className="star_rating">
                      <Rating
                        max={5}
                        precision={0.5}
                        size="large"
                        readOnly
                        value={data?.feedback?.[0]?.experienceWithGinaex}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          className="text_center"
          onClick={() => navigate("/shipment-track/feedback")}
        >
          <a href="javascript:void(0)">Show More</a>
        </div>
      </div>
    </>
  );
};

export default FeedbackReview;
