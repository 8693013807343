/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import TableComponent from "../../components/TableComponent";
import { GridColDef } from "@mui/x-data-grid";
import ComplaintDetail from "../../Modals/complaintDetail";
import { useNavigate } from "react-router-dom";
import ViewRemark from "../../Modals/remark";
import useAuth from "../../hooks/useAuth";
import { ADMIN_ROLE_ID } from "../../constants/role";
import { ReportAndIssue } from "../../types/reportAndIssue";
import { showError, showToast, showWarning } from "../../constants/toasts";
import AdminRemark from "../../Modals/adminRemark";
import { useUpdateReportAndIssueMutation } from "../../services/reportIssue";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";

const ReportIssueList = ({
  columnVisibilityModel,
  reportIssueData,
  setSelectedRow,
  gridApi,
  getDataList,
}: {
  columnVisibilityModel: any;
  reportIssueData: ReportAndIssue[];
  setSelectedRow: any;
  gridApi: any;
  getDataList: any;
}) => {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [openModal2, setOpenModal2] = useState(false);
  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };
  const [openModal3, setOpenModal3] = useState(false);
  const handleCloseModal3 = () => {
    setOpenModal3(false);
  };

  const rows: any = [];
  reportIssueData?.forEach((element) => {
    rows.push({
      id: element?._id,
      date: element?.createdAt?.slice(0, 10),
      customerName: element?.userDetail?.firstName
        ? `${element?.userDetail?.firstName} ${element?.userDetail?.lastName}`
        : "-",
      contact: element?.userDetail?.countryCode + element?.userDetail?.phone,
      image: element?.userDetail?.personVerification?.profileImage,
      userId: element?.userId,
      //   shipment: element?.orderDetails?.shipmentDetail?.[0]?.shipmentId
      //     ? element?.orderDetails?.shipmentDetail?.[0]?.shipmentId
      //     : "-",
      shipment: element?.orderDetails?.shipmentDetail?.shipmentId
        ? "SH" + element?.orderDetails?.shipmentDetail?.shipmentId
        : "-",
      complaint: element?.complaint
        ? element?.complaint
        : "No complaint text given",
      status: element?.status == 1 ? "Active" : "Closed",
      statusEnum: element?.status,
      closedBy: element?.status == 1 ? "-" : element?.adminDetail?.fullName,
      remark: element?.remark,
      adminDetail: element?.adminDetail,
    });
  });
  const [selectedId, setSelectedId] = useState("");
  const user = useAuth();
  const [complaintDetail, setComplaintDetail] = useState("");
  const [remarkDetail, setRemarkDetail] = useState({
    image: "",
    email: "",
    name: "",
    remark: "",
  });
  const [remark, setRemark] = useState("");
  const ActionCell = ({ data }: any) => {
    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
      setAnchorEl1(null);
    };
    console.log("data?.row?.status :", data?.row?.status);

    return (
      <div className="table_actions">
        <Box>
          <IconButton
            aria-controls={open1 ? "basic-menu1" : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? "true" : undefined}
            onClick={handleClick1}
          >
            <img src="/static/images/more_icon.svg" alt="" />
          </IconButton>
          <Menu
            className="setting_menu"
            id="basic-menu1"
            anchorEl={anchorEl1}
            open={open1}
            onClose={handleClose1}
            MenuListProps={{
              "aria-labelledby": "basic-button1",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                borderRadius: "8px",
                mt: 1,
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {data?.row?.statusEnum == 1 ? (
              <MenuItem
                onClick={() => {
                  setSelectedId(data?.row?.id);
                  setTimeout(() => {
                    setOpenModal3(true);
                  }, 100);
                }}
              >
                Close
              </MenuItem>
            ) : (
              <MenuItem onClick={() => handleStatusChange(1, data?.row?.id)}>
                Active
              </MenuItem>
            )}
          </Menu>
        </Box>
      </div>
    );
  };

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      flex: 1,
      minWidth: 200,
      field: "customerName",
      headerName: "Customer name",
      editable: false,
      renderCell: (data: any) => {
        return (
          <div className="user_block">
            <figure
              onClick={() =>
                user?.role == ADMIN_ROLE_ID ||
                user?.subadminRoleId?.permissions?.[6]?.isView
                  ? navigate("/profile-detail/" + data?.row?.userId)
                  : null
              }
            >
              <img
                src={
                  data?.row?.image
                    ? data?.row?.image
                    : "/static/images/user-placeholder.jpg"
                }
                alt=""
              />
            </figure>
            <p>
              <b>
                {data?.row?.customerName
                  ? data?.row?.customerName
                  : "Brandon King"}
              </b>{" "}
              {data?.row?.contact || "+923456789"}
            </p>
          </div>
        );
      },
    },
    {
      flex: 1,
      minWidth: 120,
      field: "date",
      headerName: "date",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 120,
      field: "shipment",
      headerName: "Shipment no",
      editable: false,
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 150,
      field: "complaint",
      headerName: "Complaint Detail",
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (data) => {
        return (
          <p
            className="view_link"
            onClick={() => {
              if (data?.row?.complaint?.trim() !== "No complaint text given") {
                setComplaintDetail(data?.row?.complaint);
                setTimeout(() => {
                  setOpenModal(true);
                }, 100);
              } else showWarning("No complaint");
            }}
          >
            View
          </p>
        );
      },
    },
    {
      flex: 1,
      minWidth: 120,
      field: "status",
      headerName: "Status",
      editable: false,
      sortable: false,
      renderCell: (data) => {
        return data?.row?.statusEnum == 1 ? (
          <Button className="btn_text c_success">Active</Button>
        ) : (
          <Button className="btn_text c_danger">Closed</Button>
        );
      },
    },
    {
      flex: 1,
      minWidth: 120,
      field: "closedBy",
      headerName: "closed by",
      editable: false,
      sortable: false,
      renderCell: ({ row }) => {
        return row?.closedBy == "-" ? (
          <p>{row?.closedBy}</p>
        ) : (
          <Tooltip title={row?.adminDetail?.email}>
            <p>{row?.closedBy}</p>
          </Tooltip>
        );
      },
    },
    {
      flex: 1,
      minWidth: 100,
      field: "remark",
      headerName: "remark",
      editable: false,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (data) => {
        return (
          <p
            className="view_link"
            onClick={() => {
              if (!data?.row?.remark?.trim()) {
                showWarning("No Remarks");
                return;
              }
              if (data?.row?.statusEnum == 1) {
                showWarning("Please close issue to give remarks");
                return;
              }
              setRemarkDetail({
                image: data?.row?.adminDetail?.image,
                email: data?.row?.adminDetail?.email,
                name: data?.row?.adminDetail?.fullName,
                remark: data?.row?.remark,
              });
              setTimeout(() => {
                setOpenModal2(true);
              }, 100);
            }}
          >
            View
          </p>
        );
      },
    },
    {
      flex: 1,
      minWidth: 60,
      field: "action",
      headerName: "Action",
      editable: false,
      sortable: false,
      renderCell: (data) => {
        return <ActionCell data={data} />;
      },
    },
  ];

  const [updateData] = useUpdateReportAndIssueMutation();
  const handleStatusChange = async (status: number, id: string) => {
    try {
      const body: { status: number; remark?: string } = { status };
      if (status == 2) {
        body["remark"] = remark;
      }
      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await updateData({
        query: 1,
        body: encryptedBody,
        id: id,
      }).unwrap();
      if (response?.statusCode == 200) {
        showToast("Status updated successfully");
        getDataList();
      }
    } catch (error: any) {
      console.log(error);
      showError(error?.data?.message || "Something went wrong");
    }
  };
  return (
    <>
      <div className="sorting_table">
        <TableComponent
          columns={columns}
          rows={rows}
          columnVisibilityModel={columnVisibilityModel}
          gridApi={gridApi}
          setSelectedRow={setSelectedRow}
        />
      </div>

      <ComplaintDetail
        open={openModal}
        onClose={handleCloseModal}
        setOpen={setOpenModal}
        data={complaintDetail}
        value="Complaint"
      />
      <ViewRemark
        open={openModal2}
        onClose={handleCloseModal2}
        setOpen={setOpenModal2}
        data={remarkDetail}
      />
      <AdminRemark
        open={openModal3}
        onClose={handleCloseModal3}
        setOpen={setOpenModal3}
        handleStatusChange={() => {
          handleStatusChange(2, selectedId);
        }}
        reason={remark}
        setReason={setRemark}
      />
    </>
  );
};

export default ReportIssueList;
