import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../../layout/MainContainer";
import { Box, Tabs, Tab } from "@mui/material";
import FeedbackFilter from "../../../features/shipmentTracks/feedback/feedbackFilter";
import SubmittedList from "../../../features/shipmentTracks/feedback/submittedList";
import NotYetList from "../../../features/shipmentTracks/feedback/notYetList";
import { useGridApiRef } from "@mui/x-data-grid";
import { useLazyGetFeedbackQuery } from "../../../services/shipmentTrack";
import { showError } from "../../../constants/toasts";
import { ShipmentFeedback as ShipmentFeedbackType } from "../../../types/shipmentFeedback";
import Loader from "../../../constants/Loader";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ShipmentFeedback = () => {
  const gridApi = useGridApiRef();
  const [selectedRow, setSelectedRow] = useState<any>({
    row: [],
    column: [],
  });
  const [valueTabs, setValueTabs] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [feedback, setFeedback] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalDataCount, setTotalDataCount] = useState({
    type1: 0,
    type2: 0,
    type3: 0,
  });
  const [getAllData] = useLazyGetFeedbackQuery();
  const navigate = useNavigate();
  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabs(newValue);
  };
  const setColumnVisibilityModelValue = (value: number) => {
    if (value == 1)
      setColumnVisibilityModel1({
        traveler: true,
        beneficiary: true,
        freightCustomer: true,
        shipment: true,
        currentCity: true,
        destinationCity: true,
        status: true,
      })
    else
      setColumnVisibilityModel1({
        traveler: true,
        beneficiary: true,
        freightCustomer: true,
        currentCity: true,
        destinationCity: true,
        shipment: true,
      })
    setSearchTerm("");
  }
  const [columnVisibilityModel1, setColumnVisibilityModel1] = useState<any>({
    traveler: true,
    beneficiary: true,
    freightCustomer: true,
    departureDate: true,
    shipment: true,
    currentCity: true,
    destinationCity: true,
    status: true,
  });
  const getAllDataList = async () => {
    try {
      setIsLoading(true);
      const response = await getAllData({
        page: 1,
        type: valueTabs + 1,
        query: "",
      }).unwrap();
      if (response?.statusCode == 200) {
        setFeedback(response?.data?.data);
        console.log("test123", response?.data?.data);

        // console.log("response?.data :", response?.data?.data);
        if (valueTabs + 1 == 1)
          setTotalDataCount((prevData) => ({
            ...prevData,
            type1: response?.data?.count,
          }));
        else
          setTotalDataCount((prevData) => ({
            ...prevData,
            type2: response?.data?.count,
          }));

      }
    } catch (error: any) {
      console.log("ERRROR", error);
      showError(error?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  const getAllDataListTotalCount = async (type: number) => {
    try {
      const response = await getAllData({
        page: 0,
        type: type,
        query: "",
      }).unwrap();
      if (response?.statusCode == 200) {
        if (type == 1) {
          setTotalDataCount((prevData: any) => {
            return { ...prevData, type1: response?.data?.count };
          });
        } else if (type == 2) {
          setTotalDataCount((prevData: any) => {
            return { ...prevData, type2: response?.data?.count };
          });
        } else if (type == 3) {
          setTotalDataCount((prevData: any) => {
            return { ...prevData, type3: response?.data?.count };
          });
        }
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };

  useEffect(() => {
    getAllDataList();
    // getAllDataListTotalCount(1);
    // getAllDataListTotalCount(2);
    // getAllDataListTotalCount(3);
    setColumnVisibilityModelValue(valueTabs + 1);

  }, [valueTabs]);

  const getFilteredData = (data: ShipmentFeedbackType[]) => {
    console.log(data);
    console.log(searchTerm);


    return data.filter(data => (data?.beneficiaryDetail?.name?.toLocaleLowerCase()?.includes(searchTerm?.toLocaleLowerCase())) || (data?.traveller?.firstName?.toLocaleLowerCase()?.includes(searchTerm?.toLocaleLowerCase()) || data?.traveller?.lastName?.toLocaleLowerCase().includes(searchTerm?.toLocaleLowerCase()) || data?.traveller?.fullName?.toLocaleLowerCase()?.includes(searchTerm?.toLocaleLowerCase()) || data?.customer?.firstName?.toLocaleLowerCase()?.includes(searchTerm?.toLocaleLowerCase()) || data?.customer?.lastName?.toLocaleLowerCase().includes(searchTerm?.toLocaleLowerCase()) || data?.customer?.fullName?.toLocaleLowerCase()?.includes(searchTerm?.toLocaleLowerCase())))
  }

  return (
    <>
      <Loader isLoad={isLoading} />
      <div className="main_title">
        <h1>Feedback</h1>
        <p>
          <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
          Shipment Track - Feedback
        </p>
      </div>

      <div className="table_header">
        <div className="left_s">
          <Box className="custom_tabs1" sx={{ width: "100%" }}>
            <Tabs
              value={valueTabs}
              onChange={handleChangeTabs}
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Submitted" {...a11yProps(0)} />
              <Tab label="Not yet" {...a11yProps(1)} />
            </Tabs>
          </Box>
        </div>
        <div className="right_s">
          <FeedbackFilter
            selectedRow={selectedRow}
            gridApi={gridApi}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            columnVisibilityModel={columnVisibilityModel1}
            setColumnVisibilityModel={setColumnVisibilityModel1} />
        </div>
      </div>

      <div className="cards">
        <CustomTabPanel value={valueTabs} index={0}>
          <SubmittedList
            getDataList={getAllDataList}
            setSelectedRow={setSelectedRow}
            gridApi={gridApi}
            feedback={getFilteredData(feedback)}
            columnVisibilityModel={columnVisibilityModel1} />
        </CustomTabPanel>
        <CustomTabPanel value={valueTabs} index={1}>
          <NotYetList
            getDataList={getAllDataList}
            setSelectedRow={setSelectedRow}
            gridApi={gridApi}
            feedback={getFilteredData(feedback)}
            columnVisibilityModel={columnVisibilityModel1} />
        </CustomTabPanel>
      </div>
    </>
  );
};

export default ShipmentFeedback;
