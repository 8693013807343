//import { url } from "inspector";
import { END_POINTS } from "../helpers";
// import { any } from "../types/NewOrder";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
type CommonResponseType = {
  statusCode: number;
  message: string;
};
type GetAllNewOrderResponse = {
  data: any;
  // NewOrder: any[];
  count: number;
};

type GetTokenParams = {
  // limit?: number;
  // wpagination?:boolean;
  type?: number;
  page?: number;
  id?: string;
  query?: number;
  body?: any;
};
// type EditUserById = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };

export const userApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getReportAndIssue: builder.query<
      CommonResponseType & { data: any },
      GetTokenParams
    >({
      // query:({page,limit,query})=>{
      query: ({ page, query }) => {
        let url = null;
        switch (query) {
          case 1:
            url = END_POINTS.reportIssue;
            break;
          case 2:
            url = END_POINTS.cancelAndRefund;

            break;
          case 3:
            url = END_POINTS.removeItems;
            break;
          default:
            url = END_POINTS.cancelledRequest;
            break;
        }
        const queryParams = [];
        if (page) {
          queryParams.push("wpagination=true");
        }
        if (queryParams.length > 0) {
          url += `?${queryParams.join("&")}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
    }),
    updateReportAndIssue: builder.mutation<
      CommonResponseType & { data: any },
      GetTokenParams
    >({
      query: ({ query, body, id }) => {
        let url = null;
        switch (query) {
          case 1:
            url = END_POINTS.reportIssue;
            break;
          case 2:
            url = END_POINTS.cancelAndRefund;
            break;
        }
        return {
          url: `${url}/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
});
export const {
  useLazyGetReportAndIssueQuery,
  useUpdateReportAndIssueMutation,
} = userApi;
