/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Box, IconButton, Menu, MenuItem, Button } from "@mui/material";
import TableComponent from "../../components/TableComponent";
import { GridColDef } from "@mui/x-data-grid";
import UserRating from "../../Modals/userRating";
import { useNavigate } from "react-router-dom";
import AddUser from "../../Modals/addUser";
import useAuth from "../../hooks/useAuth";
import {
  useDeleteSubAdminByIdMutation,
  useEditSubAdminByIdMutation,
} from "../../services/subAdmin";
import { handleDelete } from "../../utils/commonFunctions";
import { showToast, showError } from "../../constants/toasts";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { getElaspedTime } from "../../utils/getElapsedTime";
import ViewUser from "../../Modals/ViewUser";
import { ADMIN_ROLE_ID } from "../../constants/role";
import DeleteReview from "../../Modals/deleteReview";

const UserListTable = ({
  subAdmins,
  getSubAdminsList,
  roles,
  gridApi,
  setSelectedRow,
}: any) => {
  const navigate = useNavigate();
  const user = useAuth();
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    action: true,
  });
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedData, setSelectedData] = useState<any>(null);
  const [deleteById] = useDeleteSubAdminByIdMutation();
  const [selectedId, setSelectedId] = useState<number>(0);
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const [isEdit, setIsEdit] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [openViewUserModal, setOpenViewUserModal] = useState(false);
  const handleEditUserCloseModal = () => {
    setSelectedData(null);
    setOpenEditUserModal(false);
  };
  const handleViewUserCloseModal = () => {
    setSelectedData(null);
    setOpenViewUserModal(false);
  };
  const [updateData]: any = useEditSubAdminByIdMutation();

  const handleStatusChange = async (
    id: any,
    status: number,
    isBlocked: boolean
  ) => {
    try {
      const body = {
        accountStatus: status,
        isBlocked: isBlocked,
      };
      console.log("STATUS", id, body);
      const encryptedData = generateEncryptedKeyBody(body);
      const response = await updateData({
        id: `${id}`,
        body: encryptedData,
      }).unwrap();
      showToast("User status updated successfully");
      getSubAdminsList();
    } catch (error: any) {
      showError(error?.data?.message || "");
      //      console.log(error);
    }
  };
  const rows: any = [];
  subAdmins?.map((data: any) => {
    rows.push({
      id: data._id,
      name: data?.fullName,
      user: data?.fullName,
      email: data?.email,
      image: data?.image,
      mobile: data?.countryCode + data?.phone,
      phone: data?.phone,
      countryCode: data?.countryCode,
      role:
        data?.role == 2 ? "Super Administrator" : data?.subadminRoleId?.role,
      roleType: data?.role,
      roleId: data?.subadminRoleId?.[0]?._id,
      permissions: data?.subadminRoleId?.[0]?.permissions,
      lastLogin: getElaspedTime(data?.lastLoginDate),
      isBlocked: data?.isBlocked,
      accountStatus: data?.accountStatus,
      twoStep: data?.isBlocked ? "Disabled" : "Enabled",
      lastLoginDate: data?.lastLoginDate,
    });
  });
  useEffect(() => {
    setColumnVisibilityModel({
      action:
        user?.role == ADMIN_ROLE_ID ||
        user?.subadminRoleId?.permissions?.[6]?.isEdit ||
        user?.subadminRoleId?.permissions?.[6]?.isDelete
          ? true
          : false,
    });
  }, [user]);
  const ActionCell = ({ data }: any) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setOpen(false);
    };
    return (
      <div className="table_actions">
        {user?.role == ADMIN_ROLE_ID ||
        user?.subadminRoleId?.permissions?.[6]?.isEdit ? (
          <Box>
            <IconButton
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <img src="/static/images/more_icon.svg" alt="" />
            </IconButton>
            <Menu
              className="setting_menu"
              id="basic-menu1"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button1",
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                  borderRadius: "8px",
                  mt: 1,
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={() => {
                  handleStatusChange(
                    data?.row?.id,
                    data?.row?.accountStatus,
                    false
                  );
                }}
              >
                Enable
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleStatusChange(
                    data?.row?.id,
                    data?.row?.accountStatus,
                    true
                  );
                }}
              >
                Disable
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleStatusChange(data?.row?.id, 3, data?.row?.isBlocked);
                }}
              >
                Block
              </MenuItem>
            </Menu>
          </Box>
        ) : null}
        {user?.role == ADMIN_ROLE_ID ||
        user?.subadminRoleId?.permissions?.[6]?.isEdit ? (
          <Box>
            <IconButton
              onClick={() => {
                setSelectedData(data?.row);
                setOpenEditUserModal(true);
              }}
            >
              <img src="/static/images/edit2_icon.svg" alt="" />
            </IconButton>
          </Box>
        ) : null}
        {user?.role == ADMIN_ROLE_ID ||
        user?.subadminRoleId?.permissions?.[6]?.isDelete ? (
          <Box>
            <IconButton
              onClick={() =>
                // handleDelete(deleteById, data?.row?.id, getSubAdminsList)
                {
                  setDeleteOpen(true);
                  setSelectedIndex(data?.row?.id);
                }
              }
            >
              <img src="/static/images/trash2_icon.svg" alt="" />
            </IconButton>
          </Box>
        ) : null}
      </div>
    );
  };
  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      flex: 1,
      minWidth: 230,
      field: "user",
      headerName: "User",
      editable: false,
      renderCell: (data: any) => {
        return (
          <div className="user_block">
            <figure
              onClick={() => {
                setSelectedData(data?.row);
                setOpenViewUserModal(true);
              }}
            >
              <img
                src={
                  data?.row?.image
                    ? data?.row?.image
                    : "/static/images/user-placeholder.jpg"
                }
                alt=""
              />
            </figure>
            <p className="cursor_p" onClick={() => setOpenModal(true)}>
              <b>{data?.row?.name}</b> {data?.row?.email}
            </p>
          </div>
        );
      },
    },
    {
      flex: 1,
      minWidth: 100,
      field: "role",
      headerName: "Role",
      editable: false,
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 100,
      field: "mobile",
      headerName: "Mobile",
      editable: false,
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 100,
      field: "lastLogin",
      headerName: "Last login",
      editable: false,
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 150,
      field: "twoStep",
      headerName: "two-step",
      disableExport: true,
      editable: false,
      sortable: false,
      renderCell: (data: any) => {
        return (
          <>
            {data?.row?.accountStatus == 1 && (
              <Button className="btn_text c_black">Pending</Button>
            )}
            {data?.row?.accountStatus == 2 && !data?.row?.isBlocked && (
              <Button className="btn_text c_success">Enabled</Button>
            )}
            {data?.row?.accountStatus == 2 && data?.row?.isBlocked && (
              <Button className="btn_text c_warning">Disabled</Button>
            )}
            {data?.row?.accountStatus == 3 && (
              <Button className="btn_text c_danger">Blocked</Button>
            )}
          </>
        );
      },
    },
    {
      flex: 1,
      minWidth: 140,
      field: "action",
      headerName: "action",
      disableExport: false,
      editable: false,
      sortable: false,
      renderCell: (data) => <ActionCell data={data} />,
    },
  ];

  let hidden = ["fullName"];

  return (
    <>
      <div className="sorting_table">
        <TableComponent
          columns={columns?.filter((item) => !hidden.includes(item.field))}
          rows={rows}
          gridApi={gridApi}
          columnVisibilityModel={columnVisibilityModel}
          setSelectedRow={setSelectedRow}
        />
      </div>
      <AddUser
        open={openEditUserModal}
        onClose={handleEditUserCloseModal}
        getSubAdminsList={getSubAdminsList}
        setOpen={setOpenEditUserModal}
        roles={roles}
        selectedData={selectedData}
      />
      <ViewUser
        open={openViewUserModal}
        onClose={handleViewUserCloseModal}
        getSubAdminsList={getSubAdminsList}
        setOpen={setOpenViewUserModal}
        roles={roles}
        selectedData={selectedData}
      />
      <UserRating
        open={openModal}
        onClose={handleCloseModal}
        setOpen={setOpenModal}
      />
      <DeleteReview
        open={deleteOpen}
        setOpen={setDeleteOpen}
        text="user"
        deleteData={() =>
          handleDelete(deleteById, selectedIndex, getSubAdminsList)
        }
        onClose={() => setDeleteOpen(false)}
      />
    </>
  );
};

export default UserListTable;
