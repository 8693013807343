import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../../layout/MainContainer";
import { Tabs, Tab, Box } from "@mui/material";
import AcceptedOrdersList from "../../../features/shipmentTracks/newOrders/acceptedOrdersList";
import AcceptedOrdersFilter from "../../../features/shipmentTracks/newOrders/acceptedOrdersFilter";
import WaitingOrdersList from "../../../features/shipmentTracks/newOrders/waitingOrderList";
import WaitingOrdersFilter from "../../../features/shipmentTracks/newOrders/waitingOrderFilter";
import { useLazyGetNewOrderQuery } from "../../../services/shipmentTrack";
import { showError } from "../../../constants/toasts";
import { NewOrder } from "../../../types/newOrder";
import { useGridApiRef } from "@mui/x-data-grid";
import Loader from "../../../constants/Loader";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NewOrders = () => {
  const gridApi = useGridApiRef();
  const [selectedRow, setSelectedRow] = useState<any>({
    row: [],
    column: [],
  });

  const navigate = useNavigate();

  const [valueTabs, setValueTabs] = React.useState(0);
  const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
    setValueTabs(newValue);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [getAllData] = useLazyGetNewOrderQuery();
  const [newOrders, setNewOrders] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [totalDataCount, setTotalDataCount] = useState({
    type1: 0,
    type2: 0,
  });
  const getAllDataList = async () => {
    try {
      setIsLoading(true);
      const response = await getAllData({
        page: 1,
        type: valueTabs + 1,
        query: "",
      }).unwrap();
      if (response?.statusCode == 200) {
        setNewOrders(response?.data?.data);
        console.log("response?.data :", response?.data);
        if (valueTabs + 1 == 1)
          setTotalDataCount((prevData) => ({
            ...prevData,
            type1: response?.data?.count,
          }));
        else
          setTotalDataCount((prevData) => ({
            ...prevData,
            type2: response?.data?.count,
          }));

        getAllDataListTotalCount(2);
      }
    } catch (error: any) {
      console.log("ERRROR", error);
      showError(error?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };
  const getAllDataListTotalCount = async (type: number) => {
    try {
      const response = await getAllData({
        page: 0,
        type: type,
        query: "",
      }).unwrap();
      if (response?.statusCode == 200) {
        if (type == 1) {
          setTotalDataCount((prevData: any) => {
            return { ...prevData, type1: response?.data?.count };
          });
        } else if (type == 2) {
          setTotalDataCount((prevData: any) => {
            return { ...prevData, type2: response?.data?.count };
          });
        }
      }
    } catch (error: any) {
      showError(error?.data?.message);
    }
  };
  const [searchTerm, setSearchTerm] = useState("");
  console.log("searchTerm :", searchTerm);
  const [columnVisibilityModel1, setColumnVisibilityModel1] = useState<any>({
    freightCustomer: true,
    travelers: true,
    // contact: true,
    // email: true,
    currentCity: true,
    destinationCity: true,
    shipmentNo: true,
    offers: true,
    viewDetail: true,
    status: true,
    action: true,
    dateOfOrder: true,
    departureDate: true,
    delayDepartureDate: true,
    arrivedDate: true,
    expectingDeliveryDate: true,
    viewRemark: true,
    dateOfAcceptance: true,
  });
  const [columnVisibilityModel2, setColumnVisibilityModel2] = useState<any>({
    freightCustomer: true,
    // travelers: true,
    // contact: true,
    // email: true,
    currentCity: true,
    destinationCity: true,
    shipmentNo: true,
    offers: true,
    viewDetail: true,
    status: true,
    action: true,
    dateOfOrder: true,
    // departureDate: true,
    // delayDepartureDate: true,
    // arrivedDate: true,
    // expectingDeliveryDate: true,
    viewRemark: true,
    dateOfAcceptance: true,
  });
  useEffect(() => {
    getAllDataList();
  }, [valueTabs]);
  const getFilteredData = (data: any) => {
    if (!selectedStatus)
      return newOrders.filter((data: NewOrder) =>
        data?.shipmentId?.shipmentId.includes(searchTerm.toString())
      );

    return newOrders.filter(
      (data: NewOrder) =>
        data?.shipmentId?.shipmentId.includes(searchTerm.toString()) &&
        data?.trackingStatus == selectedStatus
    );
  };

  return (
    <>
      <Loader isLoad={isLoading} />
      <div className="main_title">
        <h1>Order Submitted</h1>
        <p>
          <span onClick={() => navigate("/dashboard")}>Dashboard</span> -
          Shipment Track - Order Submitted
        </p>
      </div>

      <Box className="custom_tabs1" sx={{ width: "100%" }}>
        <div className="table_header">
          <div className="left_s">
            <Tabs
              value={valueTabs}
              onChange={handleChangeTabs}
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab
                label={"Accepted Orders (" + totalDataCount?.type1 + ")"}
                {...a11yProps(0)}
              />
              <Tab
                label={"Waiting Orders (" + totalDataCount?.type2 + ")"}
                {...a11yProps(1)}
              />
            </Tabs>
          </div>
          <CustomTabPanel value={valueTabs} index={0}>
            <AcceptedOrdersFilter
              setSelectedStatus={setSelectedStatus}
              selectedStatus={selectedStatus}
              selectedRow={selectedRow}
              gridApi={gridApi}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              columnVisibilityModel={columnVisibilityModel1}
              setColumnVisibilityModel={setColumnVisibilityModel1}
            />
          </CustomTabPanel>
          <CustomTabPanel value={valueTabs} index={1}>
            <WaitingOrdersFilter
              selectedRow={selectedRow}
              gridApi={gridApi}
              columnVisibilityModel={columnVisibilityModel2}
              setColumnVisibilityModel={setColumnVisibilityModel2}
            />
          </CustomTabPanel>
        </div>

        <CustomTabPanel value={valueTabs} index={0}>
          <div className="cards">
            <AcceptedOrdersList
              getDataList={getAllDataList}
              setSelectedRow={setSelectedRow}
              gridApi={gridApi}
              newOrders={getFilteredData(newOrders)}
              columnVisibilityModel={columnVisibilityModel1}
            />
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={valueTabs} index={1}>
          <div className="cards">
            <WaitingOrdersList
              getDataList={getAllDataList}
              setSelectedRow={setSelectedRow}
              gridApi={gridApi}
              newOrders={getFilteredData(newOrders)}
              columnVisibilityModel={columnVisibilityModel2}
            />
          </div>
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default NewOrders;
