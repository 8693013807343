//import { url } from "inspector";
import { END_POINTS } from "../helpers";
// import { any } from "../types/NewOrder";
import emptySplitApi from "../utils/rtk";
import { CommonBody } from "../types/General";
type CommonResponseType = {
    statusCode: number;
    message: string;
};
type GetAllNewOrderResponse = {
    data: any;
    // NewOrder: any[];
    count: number;
};

type GetTokenParams = {
    // limit?: number;
    // wpagination?:boolean;
    type?: number;
    page?: number;
    query?: string;
};
// type EditUserById = {
//     image: string;
//     email: string;
//     fullName: string;
//     countryCode: string;
//     phone: string;
//     address:string;
//   };

export const userApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getMembershipLevel: builder.query<
            CommonResponseType & { data: any },
            GetTokenParams
        >({
            // query:({page,limit,query})=>{
            query: ({ page, type, query }) => {
                let url = END_POINTS.membershipLevel;
                const queryParams = [];
                if (page) {
                    queryParams.push("wpagination=true");
                }
                if (type) {
                    queryParams.push(`type=${type}`);
                }
                if (queryParams.length > 0) {
                    url += `?${queryParams.join("&")}`;
                }
                return {
                    url: url,
                    method: "GET",
                };
            },
        }),
    }),
});
export const {
    useLazyGetMembershipLevelQuery
} = userApi;
