const proxyUrl = "https://cors-anywhere.herokuapp.com/"; //proxy server

export const handleDownload = async (fileUrl: string, fileName: string) => {
  try {
    const response = await fetch(
      fileUrl
      // , { mode: "cors", cache: "no-cache" }
    );
    // const response = await fetch(proxyUrl + fileUrl);
    if (!response.ok) throw new Error("Failed to fetch file");

    const blob = await response.blob();
    const blobUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = `${fileName}.${fileUrl.split(".").pop()}`;
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    }, 100);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};
