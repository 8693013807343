import { Box, IconButton } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PieChartGraph from "../../components/PieChart";
import { useEffect, useLayoutEffect, useState } from "react";
import { useGetCountryWiseReportQuery } from "../../services/reports";
import { showError } from "../../constants/toasts";

const ReportRightCards = ({ data }: any) => {
  console.log("data mohit: ", data);
  const [flagData, setFlagData] = useState<
    {
      countryName: string;
      flag: string;
    }[]
  >([]);
  const { data: response, error, isLoading } = useGetCountryWiseReportQuery({});
  if (error) {
    //@ts-ignore
    showError(error?.data?.message || "Something went wrong");
  }
  const getFlagImage = async (flagData: any[]) => {
    for await (let key of flagData) {
      try {
        const response = await fetch(
          `https://restcountries.com/v3.1/name/${key._id}`
        );
        const data = await response.json();
        if (data) {
          setFlagData((prev) => [
            ...prev,
            { countryName: key?._id, flag: data?.[0]?.flags?.png },
          ]);
        }
      } catch (error: any) {
        console.log(error);
        setFlagData((prev) => [...prev, { countryName: key?._id, flag: "" }]);
      }
    }
  };
  useLayoutEffect(() => {
    if (response) {
      console.log("MOHIT", response);
      getFlagImage(response?.data);
    }
  }, [response]);

  return (
    <>
      <div className="cards earned_card">
        <h2>
          <strong>Total money earned by travelers</strong>
          <span>${data?.totalPrice}</span>
        </h2>
        <ul>
          <li>
            <span>
              <img src="/static/images/airplane_icon.svg" alt="icon" /> Earned
              by Air
            </span>
            <strong>
              $
              {Array.isArray(data?.totalPriceByTravelType)
                ? data?.totalPriceByTravelType?.find(
                    (data: any) => data?.travelType == "AIRLINES"
                  )?.price || "0"
                : "0"}
            </strong>
          </li>
          <li>
            <span>
              <img src="/static/images/bus_icon.svg" alt="icon" /> Earned by
              Train
            </span>
            <strong>
              $
              {Array.isArray(data?.totalPriceByTravelType)
                ? data?.totalPriceByTravelType?.find(
                    (data: any) => data?.travelType == "RAILWAY"
                  )?.price || "0"
                : "0"}
            </strong>
          </li>
          <li>
            <span>
              <img src="/static/images/ship2_icon.svg" alt="icon" /> Earned by
              Sea
            </span>
            <strong>
              ${" "}
              {Array.isArray(data?.totalPriceByTravelType)
                ? data?.totalPriceByTravelType?.find(
                    (data: any) => data?.travelType == "VOYAGE"
                  )?.price || "0"
                : "0"}
            </strong>
          </li>
          <li>
            <span>
              <img src="/static/images/people_icon.svg" alt="icon" /> Earned by
              public vehicles{" "}
            </span>
            <strong>
              ${" "}
              {Array.isArray(data?.totalPriceByTravelType)
                ? data?.totalPriceByTravelType?.find(
                    (data: any) => data?.travelType == "PUBLIC_ROAD"
                  )?.price || "0"
                : "0"}
            </strong>
          </li>
          {/* <li>
            <span>
              <img src="/static/images/profile2_icon.svg" alt="icon" /> Earned
              by private transport
            </span>
            <strong>$530K</strong>
          </li> */}
        </ul>
      </div>

      <div className="cards doughnut_card">
        <ul>
          <li>
            Total Travelers: <strong>{data?.chart?.totalTraveler || 0}</strong>
          </li>
          <li>
            Total Volunteers:{" "}
            <strong>{data?.chart?.totalVolunteer || 0}</strong>
          </li>
        </ul>

        <figure>
          <PieChartGraph
            data={[
              {
                id: 0,
                value:
                  data?.chart?.travelTypeCounts?.find(
                    (data2: any) => data2?.travelType == "PUBLIC_ROAD"
                  )?.count || 0,
                label: "Bus",
              },
              {
                id: 1,
                value:
                  data?.chart?.travelTypeCounts?.find(
                    (data2: any) => data2?.travelType == "RAILWAY"
                  )?.count || 0,
                label: "Train",
              },
              {
                id: 2,
                value:
                  data?.chart?.travelTypeCounts?.find(
                    (data2: any) => data2?.travelType == "VOYAGE"
                  )?.count || 0,
                label: "Voyage",
              },
              {
                id: 3,
                value:
                  data?.chart?.travelTypeCounts?.find(
                    (data2: any) => data2?.travelType == "AIRLINES"
                  )?.count || 0,
                label: "Airlines",
              },
              {
                id: 4,
                value:
                  data?.chart?.travelTypeCounts?.find(
                    (data2: any) => data2?.travelType == "PRIVATE_VEHICLE"
                  )?.count || 0,
                label: "Private",
              },
            ]}
            total={data?.chart?.total}
          />
          {/* <img src="/static/images/doughnut_chart.png" alt="" /> */}
        </figure>
      </div>

      <div className="cards country_card">
        <div className="d_flex">
          <Box>
            <h2>Country Export/Import</h2>
            {/* <p>Lorem ipsum is a dummy text</p> */}
          </Box>
          {/* <Box>
            <IconButton>
              <img src="/static/images/more_icon.svg" alt="Icon" />
            </IconButton>
          </Box> */}
        </div>
        <ul>
          {response?.data?.slice(0, 8)?.map((data: any, index: any) => {
            return (
              <li>
                <figure>
                  <img
                    src={
                      flagData?.find(
                        (data2: any) => data2?.countryName == data?._id
                      )?.flag || "/static/images/flag-placeholder.webp"
                    }
                    alt="Flag"
                  />
                </figure>
                <h2>
                  {data?._id ? data?._id : "Invalid name"}{" "}
                  <span>{data?.totalOrders || 0} Orders</span>
                </h2>
                <p className="c_danger">
                  <ArrowDownwardIcon /> {data?.totalImportWt || 0} Kg
                </p>
                <p className="c_success">
                  <ArrowUpwardIcon /> {data?.totalExportWt || 0} Kg
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default ReportRightCards;
