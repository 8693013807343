/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import TableComponent from "../../../components/TableComponent";
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { ADMIN_ROLE_ID } from "../../../constants/role";
import { NewOrder } from "../../../types/newOrder";
import { ORDER_TRACKING_STATUS } from "../../../constants/shipmentOrder";
import { showToast, showError } from "../../../constants/toasts";
import FreightCustomerDetail from "../../../Modals/freightCustomerDetail";
import { useEditNewOrderByIdMutation } from "../../../services/shipmentTrack";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import increaseDays from "../../../utils/increaseDays";

const DeliverTravelersList = ({
  columnVisibilityModel,
  newOrders,
  setSelectedRow,
  gridApi,
  getDataList,
}: {
  columnVisibilityModel: any;
  newOrders: NewOrder[];
  setSelectedRow: any;
  gridApi: any;
  getDataList: any;
}) => {
  const navigate = useNavigate();

  const [shipmentDetails, setShipmentDetails] = useState<any>();
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const rows: any = [
    // {
    //     id: 1,
    //     shipmentNo: "xxx 1263-64512",
    //     currentCity: "Ottawa",
    //     destinationCity: "Canberra",
    //     offers: "10 $ (50%)"
    // },
    // {
    //     id: 2,
    //     shipmentNo: "xxx 1263-64512",
    //     currentCity: "Ottawa",
    //     destinationCity: "Canberra",
    //     offers: "10 $ (50%)"
    // },
    // {
    //     id: 3,
    //     shipmentNo: "xxx 1263-64512",
    //     currentCity: "Ottawa",
    //     destinationCity: "Canberra",
    //     offers: "10 $ (50%)"
    // },
    // {
    //     id: 4,
    //     shipmentNo: "xxx 1263-64512",
    //     currentCity: "Ottawa",
    //     destinationCity: "Canberra",
    //     offers: "10 $ (50%)"
    // },
    // {
    //     id: 5,
    //     shipmentNo: "xxx 1263-64512",
    //     currentCity: "Ottawa",
    //     destinationCity: "Canberra",
    //     offers: "10 $ (50%)"
    // },
  ];
  const [updateData] = useEditNewOrderByIdMutation();
  const handleStatusChange = async (id: any, adminStatus: any) => {
    try {
      const body = {
        trackingStatus: adminStatus,
      };
      console.log(body);
      const encryptedData = generateEncryptedKeyBody(body);
      const response = await updateData({
        id: `${id}`,
        body: encryptedData,
      }).unwrap();
      showToast("Shipment order status updated successfully");
      getDataList();
    } catch (error: any) {
      showError(error?.data?.message || "");
      //      console.log(error);
    }
  };

  newOrders?.map((data: any) => {
    rows.push({
      id: data?._id,
      travelers: data?.traveller?.fullName,
      travelerImage: data?.traveller?.personVerification?.profileImage,
      travelerId: data?.traveller._id,
      travelerMobile: data?.traveller?.countryCode + data?.traveller?.phone,
      freightCustomer:
        data?.customer?.firstName + " " + data?.customer?.lastName,
      freightCustomerImage: data?.customer?.personVerification?.profileImage,
      freightCustomerId: data?.customer._id,
      freightCustomerMobile:
        data?.customer?.countryCode + data?.customer?.phone,
      shipmentNo: data?.shipmentId?.shipmentId
        ? data?.shipmentId?.shipmentId
        : "-",
      currentCity: data?.beneficiaryDetail?.customerCurrentCity
        ? data?.beneficiaryDetail?.customerCurrentCity
        : "-",
      destinationCity: data?.beneficiaryDetail?.city
        ? data?.beneficiaryDetail?.city
        : "-",
      dateOfOrder: data?.createdAt?.slice(0, 10),
      expectingDeliveryDate: increaseDays(data?.createdAt, 2)?.slice(0, 10),
      dateOfAcceptance: data?.orderAcceptedAt
      ? data?.orderAcceptedAt?.slice(0, 10)
      : "-",
      departureDate: data?.travelShipmentId?.travelDetail?.[0]?.departureDate
        ? data?.travelShipmentId?.travelDetail?.[0]?.departureDate?.slice(0, 10)
        : "-",
      arrivedDate: data?.travelShipmentId?.travelDetail?.[
        data?.travelShipmentId?.travelDetail?.length - 1
      ]?.arrivalDate
        ? data?.travelShipmentId?.travelDetail?.[
            data?.travelShipmentId?.travelDetail?.length - 1
          ]?.arrivalDate?.slice(0, 10)
        : "-",
      shipmentDetails: {
        packageDetail: data?.packageDetail,
      },
      delayDepartureDate: data?.delayDepartureDate
        ? data?.delayDepartureDate?.slice(0, 10)
        : "-",
        offers: data?.packageDetail?.length
        ? data?.packageDetail?.reduce((accumulator: any, currentValue: any) => {
            return accumulator + currentValue?.isCancelled
              ? 0
              : currentValue?.amount;
          }, 0)
        : "0",
      // offers: data?.offers ? data?.offers : "-",
      viewRemark: data?.remarks ? data?.remarks : "-",
      status: ORDER_TRACKING_STATUS[data?.trackingStatus],
      statusEnum: data?.trackingStatus,
    });
  });
  const ActionData = ({ data }: any) => {
    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl1(event.currentTarget);
    };

    const handleClose1 = () => {
      setAnchorEl1(null);
    };
    return (
      <div className="table_actions">
        <Box>
          <IconButton
            aria-controls={open1 ? "basic-menu1" : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? "true" : undefined}
            onClick={handleClick1}
          >
            <img src="/static/images/more_icon.svg" alt="" />
          </IconButton>
          <Menu
            className="setting_menu"
            id="basic-menu1"
            anchorEl={anchorEl1}
            open={open1}
            onClose={handleClose1}
            MenuListProps={{
              "aria-labelledby": "basic-button1",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                borderRadius: "8px",
                mt: 1,
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={() => handleStatusChange(data?.row?.id, 6)}>
              On the way
            </MenuItem>
            <MenuItem onClick={() => handleStatusChange(data?.row?.id, 7)}>
              Departure
            </MenuItem>
            <MenuItem onClick={() => handleStatusChange(data?.row?.id, 8)}>
              Delay Departure
            </MenuItem>
            <MenuItem onClick={() => handleStatusChange(data?.row?.id, 9)}>
              Returned
            </MenuItem>
            <MenuItem onClick={() => handleStatusChange(data?.row?.id, 10)}>
              Arrived
            </MenuItem>
            <MenuItem onClick={() => handleStatusChange(data?.row?.id, 11)}>
              Delay of Delivery
            </MenuItem>
            <MenuItem onClick={() => handleStatusChange(data?.row?.id, 12)}>
              Completed
            </MenuItem>
          </Menu>
        </Box>
      </div>
    );
  };
  const user = useAuth();
  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      flex: 1,
      minWidth: 200,
      field: "freightCustomer",
      headerName: "Freight Customers",
      editable: false,
      renderCell: (data: any) => {
        return (
          <div className="user_block">
            <figure
              onClick={() =>
                user?.role == ADMIN_ROLE_ID ||
                user?.subadminRoleId?.permissions?.[6]?.isView
                  ? navigate("/profile-detail/" + data?.row?.freightCustomerId)
                  : null
              }
            >
              <img
                src={
                  data?.row?.freightCustomerImage
                    ? data?.row?.freightCustomerImage
                    : "/static/images/user_placeholder.png"
                }
                alt=""
              />
            </figure>
            <p>
              <b>{data?.row?.freightCustomer}</b>{" "}
              {data?.row?.freightCustomerMobile}
            </p>
          </div>
        );
      },
    },
    {
      flex: 1,
      minWidth: 180,
      field: "travelers",
      headerName: "Travelers",
      editable: false,
      renderCell: (data: any) => {
        return (
          <div className="user_block">
            <figure
              onClick={() =>
                user?.role == ADMIN_ROLE_ID ||
                user?.subadminRoleId?.permissions?.[6]?.isView
                  ? navigate("/profile-detail/" + data?.row?.travelerId)
                  : null
              }
            >
              <img
                src={
                  data?.row?.travelerImage
                    ? data?.row?.travelerImage
                    : "/static/images/user_placeholder.png"
                }
                alt=""
              />
            </figure>
            <p>
              <b>{data?.row?.travelers}</b>
              {data?.row?.travelerMobile}
            </p>
          </div>
        );
      },
    },
    {
      flex: 1,
      minWidth: 120,
      field: "shipmentNo",
      headerName: "Shipment No",
      editable: false,
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 150,
      field: "currentCity",
      headerName: "Current City",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 170,
      field: "destinationCity",
      headerName: "Destination City",
      editable: false,
    },
    {
      flex: 1,
      minWidth: 100,
      field: "offers",
      headerName: "Offers",
      editable: false,
      sortable: false,
    },
    // {
    //     flex: 1,
    //     minWidth: 100,
    //     field: "contact",
    //     headerName: "Contact",
    //     editable: false,
    //     sortable: false,
    // },
    // {
    //     flex: 1,
    //     minWidth: 100,
    //     field: "email",
    //     headerName: "Email Address",
    //     editable: false,
    //     sortable: false,
    // },
    {
      flex: 1,
      minWidth: 170,
      field: "dateOfAcceptance",
      headerName: "Date Of Acceptance",
      editable: false,
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 120,
      field: "viewRemark",
      headerName: "View Remark",
      disableExport: true,
      editable: false,
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 190,
      field: "expectingDeliveryDate",
      headerName: "Expecting Delivery Date",
      editable: false,
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 120,
      field: "arrivedDate",
      headerName: "Arrived Date",
      editable: false,
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 190,
      field: "delayDepartureDate",
      headerName: "Delay Departure Date",
      editable: false,
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 150,
      field: "departureDate",
      headerName: "Departure Date",
      editable: false,
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 150,
      field: "dateOfOrder",
      headerName: "Date Of Order",
      editable: false,
      sortable: false,
    },
    {
      flex: 1,
      minWidth: 280,
      field: "status",
      align: "center",
      headerAlign: "center",
      headerName: "Status",
      editable: false,
      sortable: false,
      renderCell: (data) => {
        return (
          <>
            {data?.row?.statusEnum == 6 && (
              <Button className="btn_text c_warning">On the way</Button>
            )}
            {data?.row?.statusEnum == 7 && (
              <Button className="btn_text c_success2">Departure</Button>
            )}
            {data?.row?.statusEnum == 8 && (
              <Button className="btn_text c_warning">Delay Departure</Button>
            )}
            {data?.row?.statusEnum == 9 && (
              <Button className="btn_text c_danger">Returned</Button>
            )}
            {data?.row?.statusEnum == 10 && (
              <Button className="btn_text c_pink">Arrived</Button>
            )}
            {data?.row?.statusEnum == 11 && (
              <Button className="btn_text c_warning">Delay Delivery</Button>
            )}
            {data?.row?.statusEnum == 12 && (
              <Button className="btn_text c_success">Completed</Button>
            )}
          </>
        );
      },
    },
    {
      flex: 1,
      minWidth: 100,
      field: "viewDetail",
      headerName: "View Detail",
      editable: false,
      disableExport: true,

      sortable: false,
      renderCell: (data) => {
        return (
          <p
            className="view_link"
            onClick={() => {
              if (!data?.row?.shipmentDetails?.packageDetail) {
                showError("There is no additional data for this order");
                return;
              }
              setShipmentDetails({
                ...data?.row?.shipmentDetails,
                name: data?.row?.freightCustomer,
                image: data?.row?.freightCustomerImage,
              });
              setOpenModal(true);
            }}
          >
            View detail
          </p>
        );
      },
    },

    {
      flex: 1,
      minWidth: 80,
      field: "action",
      disableExport: true,

      headerName: "Action",
      editable: false,
      sortable: false,
      renderCell: (data) => {
        return <ActionData data={data} />;
      },
    },
  ];

  let hidden = ["fullName"];

  return (
    <>
      <div className="sorting_table">
        <TableComponent
          columns={columns}
          rows={rows}
          gridApi={gridApi}
          setSelectedRow={setSelectedRow}
          columnVisibilityModel={columnVisibilityModel}
        />
      </div>

      <FreightCustomerDetail
        open={openModal}
        onClose={handleCloseModal}
        setOpen={setOpenModal}
        shipmentDetails={shipmentDetails}
      />
    </>
  );
};

export default DeliverTravelersList;
