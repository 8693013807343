import * as XLSX from "xlsx";
export const exportToExcel = (rows: any, columns: any) => {
  // const newData = rows.map((row:any) => {
  //   delete row.tableData
  //   delete row.id
  //   delete row.image
  //   delete row.countryCode
  //   delete row.phone
  //   return row
  // })
  console.log("MOHIT rows", rows);
  console.log('MOHII columns :', columns);
  // const newData = rows.map((row: any) => {
  //   const newRow: any = row;
  //     delete newRow.id
  //     delete newRow.image
  //     delete newRow.countryCode
  //     delete newRow.phone
  //   return newRow;
  // });
  // const newData: any = [];
  // const data = rows.filter((row: any) => {
  //   let newRow: any = {};
  //   for (let key of columns) {
  //     if (row.hasOwnProperty(key.field)) {
  //       console.log("ss")
  //       newRow[key.field] = row[key.field]
  //     }
  //   }
  //   newData.push(newRow);
  // })
  const newData = rows.map((row: any) => {
    let newRow: any = {};
    for (let key of columns) {
      if (row.hasOwnProperty(key.field)) {
        console.log("ss")
        newRow[key.field] = row[key.field]
      }
    }
    return newRow
  })
  const workSheet = XLSX.utils.json_to_sheet(newData)
  const workBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workBook, workSheet, "Gina")
  //Buffer
  XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
  //Binary string
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
  //Download
  XLSX.writeFile(workBook, "Gina Admin.xlsx")


}