import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button } from "@mui/material";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import DeleteReview from "../../Modals/deleteReview";
import EditReview from "../../Modals/editReview";
import { useParams } from "react-router-dom";
import { showError, showToast } from "../../constants/toasts";
import {
  useDeleteReviewByIdMutation,
  useLazyGetReviewsQuery,
} from "../../services/review";
import { Review, ReviewData } from "../../types/review";
import { handleDelete } from "../../utils/commonFunctions";
import Pagination from "../../components/Pagination";

const ProfileReviews = ({
  setIsLoading,
}: {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  const { id } = useParams();
  const [reviews, setReviews] = useState<ReviewData>([]);
  const [getAllReviews] = useLazyGetReviewsQuery();
  const [selectedData, setSelectedData] = useState<Review>({});
  const [deleteById] = useDeleteReviewByIdMutation();

  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  let totalPages = Math.ceil(totalCount / 10);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllReviewsData = async () => {
    setIsLoading(true);
    try {
      const response = await getAllReviews({ id: id, page: page }).unwrap();
      if (response?.statusCode == 200) {
        setReviews(response?.data?.userReview);
        setTotalCount(response?.data?.count);
      }
    } catch (error: any) {
      showError(error?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllReviewsData();
  }, [page]);
  const value1 = 5;

  const [openModal1, setOpenModal1] = useState(false);
  const handleCloseModal1 = () => {
    setOpenModal1(false);
  };

  const [openModal2, setOpenModal2] = useState(false);
  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };

  function FloatingComponents({ data }: { data: Review }) {
    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
      setAnchorEl1(null);
    };
    return (
      <div className="floating_btns">
        {/* <Box>
                <IconButton                            >
                    <img src="/static/images/share_icon.svg" alt="" />
                </IconButton>
            </Box> */}
        <Box>
          <IconButton
            aria-controls={open1 ? "basic-menu1" : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? "true" : undefined}
            onClick={handleClick1}
          >
            <img src="/static/images/more3_icon.svg" alt="" />
          </IconButton>
          <Menu
            className="setting_menu v2"
            id="basic-menu1"
            anchorEl={anchorEl1}
            open={open1}
            onClose={handleClose1}
            MenuListProps={{
              "aria-labelledby": "basic-button1",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                borderRadius: "8px",
                mt: 1,
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              onClick={() => {
                setSelectedData(data);
                setOpenModal1(true);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSelectedData(data);
                setOpenModal2(true);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </Box>
      </div>
    );
  }
  return (
    <>
      <div className="profileInfo_head">
        <h2>Reviews</h2>
        <Button
          className="btn btn_secondary"
          onClick={() => {
            navigator.clipboard.writeText(
              window?.location.host + "/review/" + id
            );
            showToast("Review link copied");
          }}
        >
          Copy Review Link
        </Button>
      </div>

      <div className="review_grid gap_m">
        {reviews?.map((data: Review) => {
          return (
            <div className="single_review">
              <FloatingComponents data={data} />
              {/* <div className="floating_btns">
                        <Box>
                            <IconButton                            >
                                <img src="/static/images/share_icon.svg" alt="" />
                            </IconButton>
                        </Box>
                        <Box>
                            <IconButton
                                aria-controls={open1 ? "basic-menu1" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open1 ? "true" : undefined}
                                onClick={handleClick1}
                            >
                                <img src="/static/images/more3_icon.svg" alt="" />
                            </IconButton>
                            <Menu
                                className="setting_menu v2"
                                id="basic-menu1"
                                anchorEl={anchorEl1}
                                open={open1}
                                onClose={handleClose1}
                                MenuListProps={{
                                    "aria-labelledby": "basic-button1",
                                }}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                                        borderRadius: "8px",
                                        mt: 1,
                                    },
                                }}
                                transformOrigin={{ horizontal: "right", vertical: "top" }}
                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                            >
                                <MenuItem onClick={() => setOpenModal1(true)}>Edit</MenuItem>
                                <MenuItem onClick={() =>{ 
                                    setSelectedData(data)
                                    setOpenModal2(true)}}>Delete</MenuItem>
                            </Menu>
                        </Box>
                    </div> */}
              <figure>
                <img
                  // src="/static/images/user_attachmen2.jpg"
                  src="/static/images/user-placeholder.jpg"
                  alt="User"
                />
                <figcaption>
                  <h2>
                    <strong>{data?.name}</strong>
                    <span>{data?.createdAt?.slice(0, 10)}</span>
                  </h2>
                  <span>{data?.email}</span>
                  <Rating
                    name="read-only"
                    value={data?.rating}
                    readOnly
                    precision={0.5}
                    emptyIcon={<StarIcon />}
                  />
                </figcaption>
              </figure>
              <p>{data?.description}</p>
            </div>
          );
        })}
        {/* <div className="single_review">
                    <div className="floating_btns">
                        <Box>
                            <IconButton                            >
                                <img src="/static/images/share_icon.svg" alt="" />
                            </IconButton>
                        </Box>
                        <Box>
                            <IconButton
                                aria-controls={open1 ? "basic-menu1" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open1 ? "true" : undefined}
                                onClick={handleClick1}
                            >
                                <img src="/static/images/more3_icon.svg" alt="" />
                            </IconButton>
                            <Menu
                                className="setting_menu v2"
                                id="basic-menu1"
                                anchorEl={anchorEl1}
                                open={open1}
                                onClose={handleClose1}
                                MenuListProps={{
                                    "aria-labelledby": "basic-button1",
                                }}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                                        borderRadius: "8px",
                                        mt: 1,
                                    },
                                }}
                                transformOrigin={{ horizontal: "right", vertical: "top" }}
                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                            >
                                <MenuItem onClick={() => setOpenModal1(true)}>Edit</MenuItem>
                                <MenuItem onClick={() => setOpenModal2(true)}>Delete</MenuItem>
                            </Menu>
                        </Box>
                    </div>
                    <figure>
                        <img src="/static/images/user_attachmen2.jpg" alt="User" />
                        <figcaption>
                            <h2>
                                <strong>Craig Dokidis</strong>
                                <span>Jun 09 2021 <img src="/static/images/flag2_icon.svg" alt="Icon" /></span>
                            </h2>
                            <Rating name="read-only" value={value1} readOnly precision={0.5} emptyIcon={<StarIcon />} />
                        </figcaption>
                    </figure>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed...</p>
                </div>
                <div className="single_review">
                    <div className="floating_btns">
                        <Box>
                            <IconButton                            >
                                <img src="/static/images/share_icon.svg" alt="" />
                            </IconButton>
                        </Box>
                        <Box>
                            <IconButton
                                aria-controls={open1 ? "basic-menu1" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open1 ? "true" : undefined}
                                onClick={handleClick1}
                            >
                                <img src="/static/images/more3_icon.svg" alt="" />
                            </IconButton>
                            <Menu
                                className="setting_menu v2"
                                id="basic-menu1"
                                anchorEl={anchorEl1}
                                open={open1}
                                onClose={handleClose1}
                                MenuListProps={{
                                    "aria-labelledby": "basic-button1",
                                }}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                                        borderRadius: "8px",
                                        mt: 1,
                                    },
                                }}
                                transformOrigin={{ horizontal: "right", vertical: "top" }}
                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                            >
                                <MenuItem onClick={() => setOpenModal1(true)}>Edit</MenuItem>
                                <MenuItem onClick={() => setOpenModal2(true)}>Delete</MenuItem>
                            </Menu>
                        </Box>
                    </div>
                    <figure>
                        <img src="/static/images/user_attachmen2.jpg" alt="User" />
                        <figcaption>
                            <h2>
                                <strong>Craig Dokidis</strong>
                                <span>Jun 09 2021</span>
                            </h2>
                            <Rating name="read-only" value={value1} readOnly precision={0.5} emptyIcon={<StarIcon />} />
                        </figcaption>
                    </figure>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed...</p>
                </div>
                <div className="single_review">
                    <div className="floating_btns">
                        <Box>
                            <IconButton                            >
                                <img src="/static/images/share_icon.svg" alt="" />
                            </IconButton>
                        </Box>
                        <Box>
                            <IconButton
                                aria-controls={open1 ? "basic-menu1" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open1 ? "true" : undefined}
                                onClick={handleClick1}
                            >
                                <img src="/static/images/more3_icon.svg" alt="" />
                            </IconButton>
                            <Menu
                                className="setting_menu v2"
                                id="basic-menu1"
                                anchorEl={anchorEl1}
                                open={open1}
                                onClose={handleClose1}
                                MenuListProps={{
                                    "aria-labelledby": "basic-button1",
                                }}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.05)",
                                        borderRadius: "8px",
                                        mt: 1,
                                    },
                                }}
                                transformOrigin={{ horizontal: "right", vertical: "top" }}
                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                            >
                                <MenuItem onClick={() => setOpenModal1(true)}>Edit</MenuItem>
                                <MenuItem onClick={() => setOpenModal2(true)}>Delete</MenuItem>
                            </Menu>
                        </Box>
                    </div>
                    <figure>
                        <img src="/static/images/user_attachmen2.jpg" alt="User" />
                        <figcaption>
                            <h2>
                                <strong>Craig Dokidis</strong>
                                <span>Jun 09 2021</span>
                            </h2>
                            <Rating name="read-only" value={value1} readOnly precision={0.5} emptyIcon={<StarIcon />} />
                        </figcaption>
                    </figure>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed...</p>
                </div> */}
      </div>
      {!reviews?.length ? (
        <h1 style={{ textAlign: "center", padding: "80px" }}>No Reviews</h1>
      ) : (
        <Pagination
          module={reviews}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      )}
      <EditReview
        open={openModal1}
        onClose={handleCloseModal1}
        setOpen={setOpenModal1}
        selectedData={selectedData}
        getData={getAllReviewsData}
      />
      <DeleteReview
        text="review"
        open={openModal2}
        onClose={handleCloseModal2}
        setOpen={setOpenModal2}
        deleteData={() =>
          handleDelete(
            deleteById,
            selectedData?._id as string,
            getAllReviewsData
          )
        }
      />
    </>
  );
};

export default ProfileReviews;
